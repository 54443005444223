import React, { Component } from "react";
import "../static/css/impressum.css";

import BackNav from "../components/BackNav.js";

class Impressum extends Component {
  render() {
    return (
      <div className="Impressum">
        <BackNav pageTitel={"Impressum"} />
        <div className="Containerumallesrundherum">
          <div className="Container">
            <div className="InnerContainer1">
              <p>
                Inhaltlich verantwortlich (gem. §10 Absatz 3 MDStV):
                <hr />
                MP Gastro München GmbH & Co. KG
                <br />
                Johanneskirchnerstraße 62
                <br />
                81927 München
                <br />
                Geschäftsführer Marisa Puschinsky
                <br />
                <br />
                E-Mail: dicke-sophie(at)gmx.de
                <br />
                Telefon: 089/95 95 36 34
                <br />
                Fax: 089/99 14 89 20
                <br />
              </p>
              <p>
                Ust-ID Nr. DE364314908
                <br />
                Handelsregister HRA 118300 München
                <br />
                Gerichtsstand: München
                <br />
              </p>
            </div>
            <div className="InnerContainer2">
              <h2>Haftungsausschluss</h2>
              <div className="absatz">
                <p>
                  1. Inhalt des Onlineangebotes
                  <hr />
                  Die Autoren übernehmen keinerlei Gewähr für die Aktualität,
                  Korrektheit, Vollständigkeit oder Qualität der
                  bereitgestellten <br />
                  Informationen. Haftungsansprüche gegen die Autoren, welche sich
                  auf Schäden materieller oder ideeller Art beziehen, die durch
                  die <br />
                  Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
                  durch die Nutzung fehlerhafter und unvollständiger
                  Informationen verursacht wurden, <br />
                  sind grundsätzlich ausgeschlossen, sofern seitens der Autoren
                  kein nachweislich vorsätzliches oder grob fahrlässiges
                  Verschulden vorliegt. Alle Angebote sind freibleibend und
                  unverbindlich. Die Autoren behalten es sich ausdrücklich vor,
                  Teile der Seiten oder das gesamte Angebot <br />
                  ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu
                  löschen oder die Veröffentlichung zeitweise oder endgültig
                  einzustellen.
                </p>
              </div>
              <div className="absatz">
                <p>
                  2. Verweise und Links
                  <hr />
                  Bei direkten oder indirekten Verweisen auf fremde Webseiten
                  („Hyperlinks“), die außerhalb des Verantwortungsbereiches des
                  Autors liegen, würde eine <br />
                  Haftungsverpflichtung ausschließlich in dem Fall in Kraft
                  treten, in dem die Autoren von den Inhalten Kenntnis haben und es
                  ihnen technisch möglich und zumutbar wäre,
                  <br />
                  die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Die
                  Autoren erklären hiermit ausdrücklich, dass zum Zeitpunkt der
                  Linksetzung keine illegalen Inhalte auf den zu verlinkenden
                  Seiten erkennbar waren.
                  <br />
                  Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder
                  die Urheberschaft der verlinkten/verknüpften Seiten haben die
                  Autoren keinerlei Einfluss.
                  <br />
                  Deshalb distanzieren sie sich hiermit ausdrücklich von allen
                  Inhalten aller verlinkten /verknüpften Seiten, die nach der
                  Linksetzung verändert wurden.
                  <br />
                  Diese Feststellung gilt für alle innerhalb des eigenen
                  Internetangebotes gesetzten Links und Verweise sowie für
                  Fremdeinträge in von den Autoren eingerichteten
                  <br />
                  Gästebüchern, Diskussionsforen, Linkverzeichnissen,
                  Mailinglisten und in allen anderen Formen von Datenbanken, auf
                  deren Inhalt externe Schreibzugriffe möglich sind.
                  <br />
                  Für illegale, fehlerhafte oder unvollständige Inhalte und
                  insbesondere für Schäden, die aus der Nutzung oder
                  Nichtnutzung solcherart dargebotener Informationen entstehen,
                  <br />
                  haftet allein der Anbieter der Seite, auf welche verwiesen
                  wurde, nicht derjenige, der über Links auf die jeweilige
                  Veröffentlichung lediglich verweist.
                </p>
              </div>
              <div className="absatz">
                <p>
                  3. Urheber- und Kennzeichenrecht
                  <hr />
                  Die Autoren übernehmen keinerlei Gewähr für die Aktualität,
                  Korrektheit, Vollständigkeit oder Qualität der
                  bereitgestellten <br />
                  Informationen. Haftungsansprüche gegen die Autoren, welche sich
                  auf Schäden materieller oder ideeller Art beziehen, die durch
                  die <br />
                  Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
                  durch die Nutzung fehlerhafter und unvollständiger
                  Informationen verursacht wurden, <br />
                  sind grundsätzlich ausgeschlossen, sofern seitens der Autoren
                  kein nachweislich vorsätzliches oder grob fahrlässiges
                  Verschulden vorliegt. Alle Angebote sind freibleibend und
                  unverbindlich. Die Autoren behalten es sich ausdrücklich vor,
                  Teile der Seiten oder das gesamte Angebot <br />
                  ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu
                  löschen oder die Veröffentlichung zeitweise oder endgültig
                  einzustellen. Icons von: <a href="https://react-icons.github.io/react-icons/">react-icons</a>

                  <hr></hr>
                  <br></br>
                  Website erstellt durch <a href="https://dizzweb.com">dizzweb.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Impressum;