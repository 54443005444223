import React, { Component } from "react";

import "../static/css/kontakt.css"
// import ReactDOM from 'react-dom';
// import { BrowserRouter } from "react-router-dom";

import BackNav from "../components/BackNav.js";
import emailjs from 'emailjs-com';
import {GrMail} from "react-icons/gr";
import {FaMapMarkerAlt} from "react-icons/fa";
import {AiFillPhone} from "react-icons/ai";


class Kontakt extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 10000);
    });
  };
  componentDidMount() {
    window.scrollTo(-100, 0);
  };
  
  render() {

    function sendEmail(e) {
      e.preventDefault();
      emailjs
        .sendForm(
          "gmail",
          "template_abpk8l8",
          e.target,
          "user_yIjZ1g3tiEF3UHWQC1SEu"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();

    }

    return (
      <div className="Kontakt">
        <BackNav pageTitel={"Kontakt"} />
        <section className="konbanner">
          <div className="koncard-container">
            <div className="koncard-img">
              <div className="textfeld">
                Bei Fragen, Anregungen oder Wünschen zu unserem Wirtshaus
                kontaktieren Sie uns gerne. <br />
                Auch Fragen zu möglichen Jobs können sie hier stellen. <br />
                <br />
                Wir versuchen, Ihnen schnellstmöglich zu antworten.
              </div>
              <div className="iconrow">
                <div className="icontext" id="icontext1">
              <div className="icon">
              <FaMapMarkerAlt className="FaMapMarkerAlt"
              color="#000"
              size="2.5em"
              />
              </div>
              <a className="iconlink" href="https://www.google.de/maps/place/Dicke+Sophie/@48.1692161,11.6448717,17z/data=!3m1!4b1!4m5!3m4!1s0x479e74df3308df35:0xd42e0437917ccaa1!8m2!3d48.1692127!4d11.6470581">Johanneskirchnerstraße 62 <br/>
              81927 München</a>
              </div>
              <div className="icontext" id="icontext2">
              <div className="icon">
              <AiFillPhone className="AiFillPhone"
              color="#000"
              size="2.5em"
              />
              </div>
              <a className="iconlink" id="iconlink2" href="tel:089 95953634">08995953634</a>
              </div>
              <div className="icontext" id="icontext3">
              <div className="icon">
              <GrMail className="GrMail"
              color="#000"
              size="2.5em"
              />
              </div>
              <a className="iconlink" id="iconlink3" href="mailto:dicke-sophie@gmx.de ">dicke-sophie@gmx.de </a>
              </div>
              </div>
            </div>
            <div className="koncard-content">
              <h3 className="schreibuns">Schreib uns!</h3>
              <form
                className="konFormWrap"
                onSubmit={(e) => {
                  sendEmail(e);
                  this.onShowAlert();
                }}
              >
                <div className="konform-row">
                  <input
                    name="name"
                    type="text"
                    placeholder="Name"
                    required
                  ></input>
                  <input
                    name="telefon"
                    type="text"
                    placeholder="Telefonnummer (optional)"
                  ></input>
                </div>

                <div className="konform-row">
                  <input
                    name="email"
                    type="text"
                    placeholder="Email"
                    required
                  ></input>
                  <input
                    name="betreff"
                    type="text"
                    placeholder="Betreff"
                    required
                  ></input>
                </div>

                <div className="konform-row">
                  <textarea
                    name="textarea"
                    type="textarea"
                    placeholder="Nachricht..."
                    className="textarea"
                    required
                  ></textarea>
                </div>

                <div className="konform-row">
                  <input type="submit" value="Senden"></input>
                </div>
              </form>
            </div>
          </div>
          <div
            className="konalert"
            style={{
              opacity: this.state.visible ? "1" : "0",
              transition: "1s",
            }}
          >
            <div style={{ border: "" }}></div>
            <h4 class="alert-heading">Vielen Dank</h4>
            <p>
              Wir haben Ihre Nachricht erhalten und versuchen Ihnen
              schnellstmöglich zu antworten.
            </p>

            <p class="mb-0">Bis Bald!</p>
          </div>
        </section>
      </div>
    );
  }
}

export default Kontakt;
