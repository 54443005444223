import React, { Component } from "react";
import "../static/css/Liste.css";
import axios from "axios";

// import Datepicker from "../components/Datepicker";

import { NavLink } from "react-router-dom";


class QrCode extends Component {
  state = {
    Vorname: "",
    Nachname: "",
    Anschrift: "",
    Datum: "",
    Time: "",
    Tischnummer: "",
    GeimpftGetestetGenesen: "",
    // Stunde: "Stunde",
    // Minute: "Minute",
  };

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const daten = {
      Vorname: this.state.Vorname,
      Nachname: this.state.Nachname,
      Anschrift: this.state.Anschrift,
      Datum: this.state.Datum,
      Time: this.state.Time,
      Tischnummer: this.state.Tischnummer,
      GeimpftGetestetGenesen: this.state.GeimpftGetestetGenesen,
    };
    axios
      .post("https://backend.dickesophie.de/api/besucher", {
        daten,
      })
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
    alert(
      `Wir lassen Ihnen eine Bestätigung-Mail zukommen. Bitte zeigen Sie diese dem Personal \n ${this.state.Anschrift}`
    );
  };

  render() {
    return (
      <div className="LisContainer">
        <div id="HeadPart">
          <h1>Willkommen bei der Dicken Sophie</h1>
          <p>
            Erhebung von Kontaktdaten von Gästen in der Gastronomie zur
            Bekämpfung der Corona-Pandemie gemäß „Hygienekonzept Gastronomie der
            Bayerischen Staatsministerien für Gesundheit und Pflege und für
            Wirtschaft, Landesentwicklung und Energie“ in Verbindung mit §§ 4
            Abs. 1 Satz 1, 13 Abs. 4 Satz 1 Nr. 6 der Siebten Bayerischen
            Infektionsschutzmaßnahmenverordnung mit datenschutzrechtlicher
            Information nach Artikel 13 Datenschutz Grundverordnung
          </p>
          <d>
            Tragen Sie bitte Ihre Kontaktdaten in das Formular unter Ziffer 1
            ein. Unter Ziffer 2 finden Sie die Informationen nach Art. 13 DSGVO
            über die Verarbeitung Ihrer personenbezogenen Daten.{" "}
          </d>
        </div>
        <form className="form-cor" onSubmit={this.handleSubmit}>
          <h1>1. Kontaktdaten, Datum</h1>
          <p id="inputDescription">Aktuelles Datum</p>
          <input
            name="Datum"
            type="date"
            required
            value={this.state.Datum}
            onChange={this.handleChange}
          />
          <p id="inputDescription">Aktuelle Uhrzeit</p>
          <input
            name="Time"
            type="time"
            min="11:00"
            max="24:00"
            required
            value={this.state.Time}
            onChange={this.handleChange}
          />
          <p id="inputDescription">Name</p>
          <input
            name="Vorname"
            type="text"
            placeholder="Vorname"
            required
            value={this.state.Vorname}
            onChange={this.handleChange}
          />
          <input
            name="Nachname"
            type="text"
            placeholder="Nachname"
            required
            value={this.state.Nachname}
            onChange={this.handleChange}
          />
          <p id="inputDescription">Tragen sie Ihre E-mail ein</p>
          <input
            name="Anschrift"
            type="email"
            placeholder="E-Mail"
            required
            value={this.state.Anschrift}
            onChange={this.handleChange}
          />
          <p id="inputDescription">Ihre Tischnummer</p>
          <input
            name="Tischnummer"
            type="number"
            placeholder="Nummer"
            required
            value={this.state.Tischnummer}
            onChange={this.handleChange}
          />
          <p id="inputDescription">Geimpft, getestet oder genesen?</p>
          <div className="checkbox"><input
            name="GeimpftGetestetGenesen"
            id="GeimpftGetestetGenesen"
            type="checkbox"
            required
            value={this.state.GeimpftGetestetGenesen}
            onChange={this.handleChange}
          /><label for="GeimpftGetestetGenesen" style={{fontWeight:'400'}}>Ja, ich bin geimpft, getestet oder genesen.</label></div>
          <input type="submit" value="Daten eintragen" id="abschicken"></input>
          <br />
          <NavLink to="/speisekarte">
            <p>Alle Daten eingetragen und abgeschickt</p>
            <p>-{">"} zur Speisekarte</p>
          </NavLink>
        </form>
        <div id="BottomPart">
          <h1>
            2. Informationen über die Verarbeitung Ihrer Daten gemäß Art. 13
            Abs. 1 und 2 DatenschutzGrundverordnung (DSGVO)
          </h1>
          <h2>
            Verantwortlicher für die Verarbeitung der erhobenen
            personenbezogenen Daten:
          </h2>
          <p>
            Geschäftsführer Marisa Puschinsky <br /> Johanneskirchnerstraße 62 |
            81927 München
            <br />
            Telefon: 089/95 95 36 34 <br />
            Fax: 089/99 14 89 20
          </p>
          <h2>Zweck und Rechtsgrundlage der Datenverarbeitung</h2>
          <p>
            Zweck: Kontaktpersonenermittlung im Fall einer festgestellten
            Infektion mit SARS-Cov-2;
          </p>
          <p>
            Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1 Buchst. d
            DSGVO (Verarbeitung personenbezogener Daten zum Schutz
            lebenswichtiger Interessen der betroffenen Person oder einer anderen
            natürlichen Person). Hierzu zählt auch die Verarbeitung
            personenbezogener Daten zur Überwachung von Epidemien und deren
            Ausbreitung.{" "}
          </p>
          <p>
            Weitere Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1
            Buchst. c DSGVO in Verbindung mit §§ 4 Abs. 1 Satz 1, 13 Abs. 4 Satz
            1 Nr. 6 der Siebten Bayer. Infektionsschutzmaßnahmenverordnung;
            veröffentlicht in BayMBl. 2020 Nr. 562 vom 01.10.2020). Diese
            Bestimmungen fordern den Inhaber des Gastronomiebetriebs zur
            Erhebung und Verarbeitung der Daten auf.
          </p>
          <h2>Empfänger der erhobenen Kontaktdaten</h2>
          <p>
            Die erhobenen Daten sind den zuständigen Gesundheitsbehörden auf
            deren Verlangen hin zu übermitteln, soweit dies zur
            Kontaktpersonenermittlung im Falle einer festgestellten Infektion
            mit dem Coronavirus SARS-CoV-2 erforderlich ist. Eine anderweitige
            Verwendung der Daten ist unzulässig. Die Befugnisse der
            Strafverfolgungsbehörden bleiben unberührt.
          </p>
          <h2>Speicherdauer</h2>
          <p>
            Die Kontaktdaten werden für einen Zeitraum von einem Monat
            aufbewahrt und dann vernichtet.
          </p>
          <h2>Ihre Rechte im Hinblick auf die Verarbeitung Ihrer Daten</h2>
          <p>
            Sie haben als betroffene Person im Hinblick auf Ihre erhobenen
            personenbezogenen Daten das Recht auf Auskunft und das Recht auf
            Berichtigung, sowie nach Ablauf der Aufbewahrungsfrist gegenüber dem
            Gastronomieunternehmen ein Recht auf Löschung Ihrer Daten. Hierzu
            können Sie sich an das Unternehmen unter o.g. Kontaktdaten wenden.
            Das Unternehmen muss unabhängig davon nach Ablauf der o.g.
            Aufbewahrungsfrist die Daten löschen.
          </p>
          <p>
            Sie haben ein Recht auf Beschwerde bei einer
            Datenschutzaufsichtsbehörde (Bayerisches Landesamt für
            Datenschutzaufsicht, Promenade 18, 91522 Ansbach oder Postfach 1349,
            91504 Ansbach; Telefon: 0981 180093-0; Telefax 0981 180093-800;
            https://www.lda.bayern.de/de/beschwerde.html).{" "}
          </p>
        </div>
      </div>
    );
  }
}

export default QrCode;







