import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/static/css/footer.css";

import {FaEnvelope, FaFacebook, FaPhone} from "react-icons/fa";

import App from './App';
import { BrowserRouter } from "react-router-dom";
import {GiForkKnifeSpoon} from "react-icons/gi";
import { Link } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <div className="indexContainer">
      {/* Navbar könnte hier implementiert werden */}
      <App />

      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="footer-col">
              <h4>Öffnungszeiten</h4>
              <ul>
                <li>
                  <a href="#f">So - Do 11:00 - 23:00 Uhr</a>
                  <br />
                  <a href="#f">Fr + Sa 11:00 - 24:00 Uhr</a>
                  <br/>
                </li>
                
                <li>
                  <a href="#f">
                    warme Küche u. To-Go<br />
                    bis 22:00 Uhr
                  </a>
                </li>
              </ul>
            </div>
            <div class="footer-col">
              <h4>Anfahrt</h4>
              <ul>
                <li>
                  <a href="#f">Johanneskirchnerstraße 146</a>
                </li>
                <li>
                  <a href="#f">81929 München</a>
                </li>
                <li>
                  <a href="#f">Deutschland</a>
                </li>
              </ul>
            </div>
            <div class="footer-col">
              <h4>Kontakt</h4>
              <ul>
                <li>
                  <a href="mailto:dicke-sophie@gmx.de ">
                    <p>
                      <FaEnvelope /> dicke-sophie@gmx.de 
                    </p>
                  </a>
                </li>
                <li>
                  <a href="tel:089 95953634">
                    <p>
                      <FaPhone /> 089 95953634
                    </p>
                  </a>
                </li>
                <li>
                  <a href="https://de-de.facebook.com/pages/category/Beer-Garden/Dicke-Sophie-154326594600417/">
                    <p>
                      {" "}
                      <FaFacebook /> Facebook
                    </p>
                  </a>
                </li>
                {/* <li><a href="https://www.instagram.com/">Instagram</a></li> */}
                <li>
                  <a href="https://wohin-essen.de/restaurantliste/restaurant/dicke-sophie.html">
                    <p>
                      <GiForkKnifeSpoon /> wohinessen.de
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="links">
            <Link to="/Impressum">Impressum</Link>
            <Link to="/Datenschutz">Datenschutz</Link>
          </div>
        </div>
      </footer>
    </div>
  </BrowserRouter>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();