import React, { Component } from "react";
import "../static/css/datenschutz.css";
import { Link } from "react-router-dom";

import BackNav from "../components/BackNav.js";



class Datenschutz extends Component {
  render() {
    return (
      <div className="Impressum">
        <BackNav pageTitel={"Datenschutz"} />
        <div className="Containerumallesrundherum">
          <div className="Container">
            <div className="InnerContainer">
              <p>
                Sofern innerhalb des Internetangebotes die Möglichkeit zur
                Eingabe persönlicher oder geschäftlicher Daten (Emailadressen,
                Namen, Anschriften) besteht,
                <br />
                so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf
                ausdrücklich freiwilliger Basis.
                <br />
                Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist
                – soweit technisch möglich und zumutbar – auch ohne Angabe
                solcher Daten bzw.
                <br />
                unter Angabe anonymisierter Daten oder eines Pseudonyms
                gestattet. Die Nutzung der im Rahmen des Impressums oder
                vergleichbarer Angaben veröffentlichten Kontaktdaten wie
                Postanschriften,
                <br />
                Telefon- und Faxnummern sowie Emailadressen durch Dritte zur
                Übersendung von nicht ausdrücklich angeforderten Informationen
                ist nicht gestattet.
                <br />
                Rechtliche Schritte gegen die Versender von sogenannten
                Spam-Mails bei Verstössen gegen dieses Verbot sind ausdrücklich
                vorbehalten.
                <br />
                Inhaltlich verantwortlich (gem. §10 Absatz 3 MDStV):
                <br />
                <br />
                MP Gastro München GmbH & Co. KG
                <br />
                Johanneskirchnerstraße 62
                <br />
                81927 München
                <br />
                Geschäftsführer Marisa Puschinsky
                <br />
                <br />
                E-Mail: dicke-sophie@gmx.de
                <br />
                <br />
                Telefon: 089/ 95 95 36 34
                <br />
                Fax: 089/ 99 14 89 20
                <br />
                Ust-ID Nr. DE364314908                <br />
                Handelsregister HRA 118300 München
                <br />
                Gerichtsstand: München
                <br />
                <br />
                <br />
                Rechtswirksamkeit dieses Haftungsausschlusses
                <br />
                <br />
                Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
                betrachten, von dem aus auf diese Seite verwiesen wurde.
                <br />
                Sofern Teile oder einzelne Formulierungen dieses Textes der
                geltenden Rechtslage nicht, nicht mehr oder nicht vollständig
                entsprechen sollten,
                <br />
                bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und
                ihrer Gültigkeit davon unberührt.

                <p>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
                <p>dizzweb Online-Services</p>
                <h4>Ihre Betroffenenrechte</h4>
                <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
                <ul>
                  <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                  <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                  <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                  <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
                  <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                  <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
                </ul>
                <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
                <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
                <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="nofollow noopener">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>
                <p></p><h4>Cookies</h4>
                <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Webseite besuchen. </p>
                <p>Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die notwendigen Informationen unter den nachfolgenden Links:</p>
                <ul>
                  <li>Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen" target="_blank" rel="nofollow noopener">https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen</a></li>
                  <li>Internet Explorer: <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="nofollow noopener">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                  <li>Google Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank" rel="nofollow noopener">https://support.google.com/accounts/answer/61416?hl=de</a></li>
                  <li>Opera: <a href="http://www.opera.com/de/help" target="_blank" rel="nofollow noopener">http://www.opera.com/de/help</a></li>
                  <li>Safari: <a href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE" target="_blank" rel="nofollow noopener">https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</a></li>
                </ul>
                <h4>Speicherdauer und eingesetzte Cookies:</h4>
                <p>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies erlauben, können folgende Cookies auf unseren Webseiten zum Einsatz kommen: Technisch notwendige Cookies</p>
                <p></p>
                <h4>Art und Zweck der Verarbeitung: </h4>
                <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann.</p>
                <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</p>
                <p>Für folgende Anwendungen benötigen wir Cookies:</p>
                <p></p><h4>Rechtsgrundlage und berechtigtes Interesse: </h4>
                <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer nutzerfreundlichen Gestaltung unserer Website.</p>
                <h4>Empfänger: </h4>
                <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.</p>
                <p></p><h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
                <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein.</p>
                <h4>Widerspruch</h4>
                <p>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO weiter unten.</p>
                <p></p>
                <h4>Art und Zweck der Verarbeitung:</h4>
                <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional.</p>
                <h4>Rechtsgrundlage:</h4>
                <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO).</p>
                <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert.</p>
                <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO).</p>
                <h4>Empfänger:</h4>
                <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
                <p></p><h4>Speicherdauer:</h4>
                <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht.</p>
                <p>Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen. </p>
                <h4>Bereitstellung vorgeschrieben oder erforderlich:</h4>
                <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.</p>
                <p></p><h4>SSL-Verschlüsselung</h4>
                <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
                <p></p>
                <hr></hr>
                <h4>Einzelfallbezogenes Widerspruchsrecht</h4>
                <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.</p>
                <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                <h4>Empfänger eines Widerspruchs</h4>
                <p>dizzweb Online-Services</p>
                <hr></hr>
                <h4>Änderung unserer Datenschutzbestimmungen</h4>
                <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.</p>
                <h4>Fragen an den Datenschutzbeauftragten</h4>
                <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>
                <p>info@dizzweb.com</p>
                <p><em>Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt, den Experten für <a href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/" target="_blank" rel="noopener">externe Datenschutzbeauftragte</a> (Version #2020-09-30).</em></p>


              </p>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Datenschutz;