import React, { Component } from "react";
import axios from "axios";
import "../static/css/AdminStyle/admin.css";

import BackNav from "../components/BackNav.js"
import aktuellesIMG from "../static/img/admin/botschaft.png"
import veranstaltungenIMG from "../static/img/admin/kalender.png"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"


class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aktuellesInput: "",
      veranstaltungenInput: "",
      Aktuellesvisible: false,
      Veranstaltungenvisible: false,
      Password:"",
      SeitewirdAngezeigt: false,
    };
  }

  onShowAlert = () => {
    this.setState({ Aktuellesvisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ Aktuellesvisible: false });
      }, 5000);
    });
  };

  onShowAlertVeranstaltungen = () => {
    this.setState({ Veranstaltungenvisible: true }, () => {
      window.setTimeout(() => {
        this.setState({ Veranstaltungenvisible: false });
      }, 5000);
    });
  };

  submitHandlerA = (e) => {
    this.setState({ ErfolgreichHochgeladen: true }); // for mesage response
    e.preventDefault();
    const aktuelles = { 
      aktuellesInput: this.state.aktuellesInput,
    };
    axios
      .post("https://backend.dickesophie.de/api/aktuelles", {
        aktuelles,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
  };

  submitHandlerV = (e) => {
    this.setState({ ErfolgreichHochgeladen: true }); // for mesage response
    e.preventDefault();
    const veranstaltungen = {
      veranstaltungenInput: this.state.veranstaltungenInput,
    };
    axios
      .post("https://backend.dickesophie.de/api/veranstaltungen", {
        veranstaltungen,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      });
  };

  updateQueryA = (aktuellesInput) => {
    this.setState(() => ({
      aktuellesInput: aktuellesInput,
    }));
  };

  updateQueryV = (veranstaltungenInput) => {
    this.setState(() => ({
      veranstaltungenInput: veranstaltungenInput,
    }));
  };
  
  // Pasword Input
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const password = {
      passwordInput: this.state.Password,
    };
    axios
    .post("https://backend.dickesophie.de/api/checkpass", {
      password,
    })
    .then((response) => {
      this.setState({
        SeitewirdAngezeigt: response.data
      });
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
      window.location.reload();
    });
  };

  render() {
    const { aktuellesInput } = this.state;
    const { veranstaltungenInput } = this.state;
    let Display;
    if (this.state.SeitewirdAngezeigt) {
      Display = (
        <div>
          <BackNav pageTitel={"Admin Page"} />
          <Container>
            <Row>
              <Col>
                <div
                  style={{ marginBottom: "40px" }}
                  onSubmit={this.submitHandlerA}
                >
                  <div
                    id="adminAlert"
                    style={{
                      opacity: this.state.Aktuellesvisible ? "1" : "0",
                      transition: "1s",
                    }}
                  >
                    <h4 class="alert-heading">Erfolgreich Hochgeladen</h4>
                  </div>
                  <form>
                    <div className="Inputwrapper">
                      <div classNmae="Aktuelles">
                        <ul className="AktVerList">
                          <li>
                            {" "}
                            <Image
                              className="adminImageAktVer"
                              src={aktuellesIMG}
                              rounded
                            />{" "}
                          </li>
                          <li xs={6}>
                            {" "}
                            <h2>Aktuelles</h2>
                          </li>
                        </ul>
                        <div>
                          <textarea
                            className="aktuellesInput"
                            placeholder="z.B. Wir haben leider wegen..."
                            value={`${aktuellesInput}`}
                            onChange={(event) =>
                              this.updateQueryA(event.target.value)
                            }
                          />
                        </div>
                      </div>

                      <Button
                        variant="success"
                        classNmae="AktuellesSubmit"
                        type="submit"
                        onClick={() => {
                          this.onShowAlert("aktuelles");
                        }}
                      >
                        Hochladen
                      </Button>
                    </div>
                  </form>
                </div>
              </Col>
              <Col>
                <div onSubmit={this.submitHandlerV}>
                  <div
                    id="adminAlert"
                    style={{
                      opacity: this.state.Veranstaltungenvisible ? "1" : "0",
                      transition: "1s",
                    }}
                  >
                    <h4 class="alert-heading">Erfolgreich Hochgeladen</h4>
                  </div>
                  <form>
                    <div className="Inputwrapper">
                      <div classNmae="Aktuelles">
                        <ul className="AktVerList">
                          <li>
                            {" "}
                            <Image
                              className="adminImageAktVer"
                              src={veranstaltungenIMG}
                              rounded
                            />{" "}
                          </li>
                          <li xs={6}>
                            {" "}
                            <h2>Veranstaltungen</h2>
                          </li>
                        </ul>
                        <div>
                          <textarea
                            className="aktuellesInput"
                            placeholder="z.B. Liebe Gäste wir bieten vom..."
                            value={`${veranstaltungenInput}`}
                            onChange={(event) =>
                              this.updateQueryV(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <Button
                        variant="success"
                        classNmae="AktuellesSubmit"
                        type="submit"
                        onClick={() => {
                          this.onShowAlertVeranstaltungen("aktuelles");
                        }}
                      >
                        Hochladen
                      </Button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>

          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
            integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
            crossorigin="anonymous"
          />
          <script
            src="https://unpkg.com/react/umd/react.production.min.js"
            crossorigin
          ></script>

          <script
            src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
            crossorigin
          ></script>

          <script
            src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
            crossorigin
          ></script>

          <script>var Alert = ReactBootstrap.Alert;</script>
        </div>
      );
    } else {
      Display = (
        <div className="Password">
          <form className="PaswordFrom" onSubmit={this.handleSubmit}>
            <h1>Passwort</h1>
            <input
              name="Password"
              type="password"
              required
              value={this.state.Password}
              onChange={this.handleChange}
            />
            <input type="submit" value="Einlogen"></input>
          </form>
        </div>
      );
    }

    return <div>{Display}</div>;
  }
}

export default Admin;

