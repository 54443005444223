import React, {Component } from "react";
import "../static/css/reservieren.css";
import 'react-datepicker/dist/react-datepicker.css';

import BackNav from "../components/BackNav.js";

import Button from "react-bootstrap/Button";
// import Datepicker from '../components/Datepicker';
import emailjs from 'emailjs-com';
import { jsPDF } from "jspdf";
import image from '../static/img/Kostenubernahmeformular-1.png'


class Reservieren extends Component {

  state = {
    date: "",
    uhrzeit: ""
  };

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  };

pdfGenerate=()=>{

  var doc=new jsPDF('p','px','a4','false');
  doc.addImage(image,'png',40,15,357,505)
  doc.save('reserv.-u.kostenuebernahmebest.pdf')

}

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 10000);
    });
  };
  componentDidMount() {
    window.scrollTo(-100, 0);
  };

  render() {

    function sendEmail(e) {
      e.preventDefault();
      emailjs
        .sendForm(
          "gmail",
          "template_7x1zvxe",
          e.target,
          "user_yIjZ1g3tiEF3UHWQC1SEu"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      e.target.reset();

    }


    return (
      <div className="Reservieren">
        <BackNav pageTitel={"Reservieren"} />
        <section className="banner">
          <div className="card-container">
            <div className="card-img">{/* <!-- image here --> */}</div>

            <div className="card-content">
              <form
                className="FormWrap"
                onSubmit={(e) => {
                  sendEmail(e);
                  this.onShowAlert();
                }}
              >
                <div className="form-row">
                  {/* <Datepicker /> */}
                  <input
                    name="date"
                    type="date"
                    required
                    value={this.state.date}
                    onChange={this.handleChange}
                  />
                  <select name="uhrzeit" required>
                    <option value="hour-select">Uhrzeit wählen</option>
                    <option value="11:00 Uhr">11:00 Uhr</option>
                    <option value="12:00 Uhr">12:00 Uhr</option>
                    <option value="13:00 Uhr">13:00 Uhr</option>
                    <option value="14:00 Uhr">14:00 Uhr</option>
                    <option value="15:00 Uhr">15:00 Uhr</option>
                    <option value="16:00 Uhr">16:00 Uhr</option>
                    <option value="17:00 Uhr">17:00 Uhr</option>
                    <option value="18:00 Uhr">18:00 Uhr</option>
                    <option value="19:00 Uhr">19:00 Uhr</option>
                    <option value="20:00 Uhr">20:00 Uhr</option>
                    <option value="21:00 Uhr">21:00 Uhr</option>
                  </select>
                </div>

                <div className="form-row">
                  <input
                    name="name"
                    type="text"
                    placeholder="Name"
                    required
                  ></input>
                  <input
                    name="telefon"
                    type="text"
                    placeholder="Telefon"
                    required
                  ></input>
                </div>

                <div className="form-row">
                  <input
                    name="email"
                    type="text"
                    placeholder="Email"
                    required
                  ></input>
                  <input
                    name="anzahl"
                    type="number"
                    placeholder="Anzahl Personen"
                    min="1"
                    required
                  ></input>
                </div>

                <div className="form-row">
                  <input
                    name="textarea"
                    type="text"
                    placeholder="Anmerkungen..."
                    className="textarea"
                  ></input>
                </div>

                <div className="form-row">
                  <p>
                    Bei Anfragen ab 24h vor dem Termin kann es zu Engpässen
                    kommen
                  </p>
                  <input
                    type="submit"
                    value="Tisch buchen"
                    onSubmit={() => {
                      this.onShowAlert();
                    }}
                  ></input>
                </div>
                <div className="formrow">
                  <div className="download">
                    <p className="downloadP">
                      Reservierungs- u. Kostenübernahmebestätigung zum Download
                    </p>
                    <Button
                      variant="outline-secondary"
                      className="downloadButton"
                      onClick={this.pdfGenerate}
                    >
                      PDF Herunterladen
                    </Button>{" "}
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div
            className="alert"
            style={{
              opacity: this.state.visible ? "1" : "0",
              transition: "1s",
            }}
          >
            <div style={{ border: "" }}></div>
            <h4 class="alert-heading">Reservierung erfolgreich</h4>
            <p>
              Ihre Anfrage wurde versendet. <br></br> Wir schicken Ihnen in
              Kürze eine Bestätigung für die Reservierung des Tisches per Email
              zu.
            </p>

            <p class="mb-0">
              Die Reservierung ist erst nach Erhalt der Bestätigung gültig
            </p>
          </div>
        </section>

        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js"
          integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ=="
          crossorigin="anonymous"
        ></script>
      </div>
    );
  }
}
export default Reservieren;