import React, { Component } from "react";

import "../static/css/gallerie.css"

import BackNav from "../components/BackNav.js"


import p1 from "../static/img/Gallerie/1.jpg"
import p2 from "../static/img/Gallerie/2.jpg";
import p3 from "../static/img/Gallerie/3.jpg";
import p4 from "../static/img/Gallerie/4.jpg";
import p5 from "../static/img/Gallerie/5.jpg";
import p6 from "../static/img/Gallerie/6.jpg";
import p7 from "../static/img/Gallerie/7.jpg";
import p8 from "../static/img/Gallerie/8.png";
import p9 from "../static/img/Gallerie/9.jpg";





class Gallerie extends Component {
  
  
  render() {
    return (
      <div className="Gallerie">
        <BackNav pageTitel={"Galerie"} />
        <div className="imgContainer">
          <img src={p1} alt=" "/>
          <img src={p2} alt=" "/>
          <img src={p3} alt=" "/>
          <img src={p4} alt=" "/>
          <img src={p5} alt=" "/>
          <img src={p6} alt=" "/>
          <img src={p7} alt=" "/>
          <img src={p8} alt=" "/>
          <img src={p9} alt=" "/>
          
        </div>
      </div>
    );
  }
}

export default Gallerie;
