import React, {Component} from "react";
import "../static/css/HomeStyle/home.css"
import { Link, NavLink } from "react-router-dom";

import Oeffnungszeiten from "../components/HomeComponents/Öffnungszeiten.js"
import Aktuelles from "../components/HomeComponents/Aktuelles.js"
import Events from "../components/HomeComponents/Events.js"

import Map from "../components/HomeComponents/Map.js"

// import BierHan from "../static/img/Zapfhan.jpg";
// import mapCheat from "../static/img/home/Capture2.PNG";
import teamimg from "../static/img/team1.png";

import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";



import Logo from "../static/img/Logo.png"
import map from "../static/img/home/map.png";

// import backgroundImg from "../static/img/backgroundImg2.jpg";
import i1 from "../static/img/home/HomeBackGround/1.jpg"
import i2 from "../static/img/home/HomeBackGround/2.jpg";
import i3 from "../static/img/home/HomeBackGround/3.jpg";
import i4 from "../static/img/home/HomeBackGround/4.jpg";
import i5 from "../static/img/home/HomeBackGround/5.jpg";
import i6 from "../static/img/home/HomeBackGround/6.jpg";
import i7 from "../static/img/home/HomeBackGround/7.jpg";
import i8 from "../static/img/home/HomeBackGround/8.jpg";
import i9 from "../static/img/home/HomeBackGround/9.jpg";
import i10 from "../static/img/home/HomeBackGround/10.jpg";
import i11 from "../static/img/home/HomeBackGround/11.jpg";
import i12 from "../static/img/home/HomeBackGround/12.jpg";
import i13 from "../static/img/home/HomeBackGround/13.jpg";

class Home extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    
    document.body.style.overflow = "auto";
  
  }
  render() {
    return (
      <div className="homeContainer">
        <div className="NavContainer">
          <div className="NavBar">
            <ul className="ul">
              <li className="li">
                <Link to="/kontakt" className="NavLink">
                  Kontakt
                </Link>
              </li>
              <p>/</p>
              <li className="li">
                <Link to="/galerie" className="NavLink">
                  Galerie
                </Link>
              </li>
              <p>/</p>
              <li className="li">
                <Link to="/historie" className="NavLink">
                  Historie
                </Link>
              </li>
              <p>/</p>
              <li className="li">
                <Link to="/philosphie" className="NavLink">
                  Philosophie
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="lowNav">
          <Link to="/Impressum" className="LowNavLink">
            Impressum
          </Link>
          <Link to="/Datenschutz" className="LowNavLink">
            Datenschutz
          </Link>
        </div>

        <div className="Center">
          <div className="fixed">
            <div>
              <div className="HeaderContainer">
                <img src={Logo} className="Logo" alt=" " />
                <h1>Dicke Sophie</h1>
              </div>
              <div className="ButtonContainer">
                <div className="quickNavButtons" id="leftButton">
                  <NavLink to="/speisekarte">
                    <p>Speisekarte</p>
                  </NavLink>
                </div>
                <div className="quickNavButtons" id="rightButton">
                  <NavLink to="/reservieren">
                    <p>Reservieren</p>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="scrolldown">
          <p id="srollInfo">scroll down</p>
        </div>
        <div className="FeedContainr">
          <div className="FirstRow">
            <div>
              <div className="Welcome">
                <p className="Headding">Herzlich Willkommen </p>
                <p>
                  In unserem traditionsbewussten Wirtshaus Dicke Sophie bieten
                  wir in Zusammenarbeit mit der Ayinger Brauerei und deren
                  Bierspezialitäten, traditionelle bayrische Küche in modernem
                  Gewand an.
                  <br />
                  <br /> Wir haben die Zeichen erkannt und in Zeiten des
                  Klimawandels setzen wir zunehmend auf regionale Produkte wie:
                  Freilauf-Eier und Rindfleisch vom Wiesheu-Hof in Johanneskirchen,
                  Kartoffeln und Gemüse vom Ismaninger Bauern Jakob Kraus und
                  Spargel direkt vom Spargel Bauer Florian Raba aus Adelshausen,
                  um nur einige zu nennen.
                  <br /> Des Weiteren setzen wir zusehends auf vegetarische und
                  vegane Gerichte und bieten zusätzlich unsere To go Gerichte in
                  dem nachhaltigen Behälterpfandsystem von Relevo, aber auch in 
                  biologisch abbaubaren Boxen an.
                  <br />
                  <br /> Die Corona Krise hat auch uns hart getroffen, daraufhin haben
                  wir unser Restaurant auf die neuesten Hygienestandards aufgerüstet,
                  um Ihnen einen sicheren und infektionsfreien Besuch zu bieten.
                  Weiterhin können Sie unsere Gerichte To Go im Abholservice
                  telefonisch vorbestellen oder nun auch vor Ort im Biergarten unsere
                  bayrischen Spezialitäten und Biere genießen.
                  <br />
                  <br />
                  Bitte bleiben Sie gesund und besuchen Sie uns in der Dicken
                  Sophie. Wir freuen uns darauf. <br></br>Ihr Dicke Sophie Team
                </p>
              </div>
              <div className="ExtLinks">
                <p>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/Dicke-Sophie-154326594600417/?ref=stream"
                    rel="noreferrer"
                  >
                    FACEBOOK
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    href="https://wohin-essen.de/restaurantliste/restaurant/dicke-sophie.html"
                    rel="noreferrer"
                  >
                    WOHINESSEN.DE
                  </a>
                </p>
              </div>
            </div>
            <div className="WelcomeBox">
            <div className="WelcomeImg">
              <img src={teamimg} alt=" " />
              </div>
              <div className="WelcomeText">
              <p>Habt Ihr Lust ein Teil unseres dynamischen & familiären Teams zu werden? Schreibt uns eine E-Mail oder ruft uns an, die Kontaktdaten findet Ihr <Link to="/kontakt" style={{color:'#000', textDecoration:'underline'}}>hier</Link>. </p>
              </div>
              </div>
            
          </div>
          <div className="SecondRow">
            <Oeffnungszeiten />
            <Aktuelles />
            <Events />
          </div>
          <div className="lastRow">
            <div className="WelcomeImg" id="AnfahrtIMg">
              {/* <Map /> */}
              <a target="_blank" href="https://goo.gl/maps/85PvP3S471hfra5F7">
                <img className="Ceatimg" src={map} alt=" " />
              </a>
            </div>
            <div className="Anfahrt">
              <p className="Headding">Anfahrt</p>
              <a
                target="_blank"
                href="https://goo.gl/maps/85PvP3S471hfra5F7"
                rel="noreferrer"
              >
                <p>81929 München</p>
                <p id="time">Johanneskirchnerstraße 146</p>
              </a>
              <p>
                2 Gehminuten von der S-Bahn „Johanneskirchen“ (Linie S8), <br />
                17 Minuten Fahrzeit zur Stadtmitte und 21 Minuten zum Flughafen,
                <br />
                15 Autominuten zur Allianzarena oder zur Messe Riem.
              </p>
            </div>
          </div>
        </div>
        <Carousel
          fade={true}
          controls={false}
          interval={5000}
          className="BackgroundImg"
          indicators={false}
        >
          <Carousel.Item>
            <img src={i1} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i2} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i3} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i4} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i5} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i6} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i7} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i8} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i9} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i10} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i11} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i12} alt=" " />
          </Carousel.Item>
          <Carousel.Item>
            <img src={i13} alt=" " />
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

//  <div style={{ width: "80%", height: "300px" }}>
//               <WrappedMap
//                 googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAuW5ByULmSfY7iFCk7WAJnyqoKbc4CGLM"
//                 loadingElement={<div style={{ height: `100%` }} />}
//                 containerElement={<div style={{ height: `100%` }} />}
//                 mapElement={<div style={{ height: `100%` }} />}
//               /> 
export default Home;


