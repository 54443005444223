import React, { Component } from "react";
import axios from "axios";
import "../../static/css/HomeStyle/Akt-Oef.css";
import	"../../static/css/HomeStyle/home.css"

class Events extends Component {
 
  state = {
    eventCtx:
      "", // @Leo Kontext für Aktuelles
  };

  componentDidMount() {
    axios
      .get("https://backend.dickesophie.de/api/veranstaltungen")
      .then((response) => {
        console.log(response);
        this.setState({
          eventCtx: response.data,
        });
        console.log(this.state.karte);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="CardContainer">
        <div className="Card">
          <p className="Headding">Veranstaltungen</p>
          <p id="time"></p>
          <div className="AktuellesText">
            <p>{this.state.eventCtx}</p>
          </div>
        </div>
      </div>
      // <div className="EventContainer">
      //   <div className="Veranstaltungen">
      //     <div className="Background">
      //       <div className="Heading">
      //         <h1>Veranstaltungen</h1>
      //       </div>
      //       <div className="information">
      //         <p>{this.state.eventCtx}</p>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default Events;
