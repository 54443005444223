import React, { Component } from "react";
import axios from "axios";


import "../../static/css/speisekarte.css";



class Tageskarte extends Component {
  state = {
    speisekarte: [],
    tageskarteDa: true
  };

  componentDidMount() {
    axios
      .get("https://backend.dickesophie.de/api/tageskarte")
      .then((response) => {
        console.log(response);
        if(response.data.length === 1){
          this.setState({
            tageskarteDa: false
          })
        }else{
          this.setState({
            tageskarteDa: true,
          });
        }
        console.log(this.state.tageskarteDa)
        this.setState({
          speisekarte: response.data,
        });
        console.log(this.state.speisekarte);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    let show;
    let tempsekarte = this.state.speisekarte;
    let speisekarte = tempsekarte.slice(0, -1);
    if (this.state.tageskarteDa) {
      show = (
        <idv>
          {speisekarte.map((Props) => (
            <div className="Gericht">
              <p className="Beschreibung">{Props}</p>
            </div>
          ))}
        </idv>
      );
    } else {
      show = (
        <div className="KeineTageskarte">
          Heute gibt es leider keine aktuelle Tageskarte. Morgen wieder!
        </div>
      );
    }
    return (
      <div id="cover">
        <h1>Tageskarte</h1>
        {show}
      </div>
    );
  }
}

export default Tageskarte;
