import { Route } from "react-router-dom";

import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "bootstrap/dist/css/bootstrap.min.css";

import CookieConsent from 'react-cookie-consent';
import { Link} from "react-router-dom";

import Home from "./viws/Home.js";
import Speisekarte from "./viws/Speisekarte.js";
import Reservieren from "./viws/Reservieren.js";
import Gallerie from "./viws/Gallerie.js";
import Historie from "./viws/Historie";
import Philosphie from "./viws/Philosophie";
import Impressum from "./viws/Impressum";
import Datenschutz from "./viws/Datenschutz";
import Admin from "./viws/admin";
import Liste from "./viws/qrcode.js";
import Kontakt from "./viws/kontakt.js";


function App() {
  return (
    <div className="appContainer">
      <Route exact path="/" render={() => <Home />} />
      <Route path="/admin" render={() => <Admin />} />
      <Route path="/speisekarte" render={() => <Speisekarte />} />
      <Route path="/reservieren" render={() => <Reservieren />} />
      <Route path="/galerie" render={() => <Gallerie />} />
      <Route path="/historie" render={() => <Historie />} />
      <Route path="/philosphie" render={() => <Philosphie />} />
      <Route path="/impressum" render={() => <Impressum />} />
      <Route path="/datenschutz" render={() => <Datenschutz />} />
      <Route path="/CoronaListe" render={() => <Liste/>}/>
      <Route path="/kontakt" render={() => <Kontakt />} />
     <CookieConsent className='cookie'
      debug={true}
      style={{ background: '#FAFAFA', position:'fixed'}}
      buttonStyle={{ color:"#000"}}
      buttonText='Basst scho!'
      expires={365}>
       <span style={{color:'#000'}} className='cookiespan'>Diese Seite benutzt Cookies. 
       Für mehr Infos besuchen sie unsere 
       <Link to='/datenschutz' style={{textDecoration:'none'}}> Datenschutz-Richtlinien</Link>.</span>
       </CookieConsent>
    </div>
  );
}

export default App;
