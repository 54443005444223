import React, { Component } from "react";
import axios from "axios";
import "../../static/css/HomeStyle/Akt-Oef.css";


class Aktuelles extends Component {
  state = {
    aktuellesCtx:
      "", // @Leo Kontext für Aktuelles
  };

  componentDidMount() {
    axios
      .get("https://backend.dickesophie.de/api/aktuelles")
      .then((response) => {
        console.log(response);
        this.setState({
          aktuellesCtx: response.data,
        });
        console.log(this.state.karte);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="CardContainer" id="spacer">
        <div className="Card">
          <p className="Headding">Aktuelles</p>
          <p id="time"></p>
          <div className="AktuellesText">
          <p>
            {this.state.aktuellesCtx}
          </p>
          </div>
        </div>
      </div>
      
    );
  }
}

export default Aktuelles;
