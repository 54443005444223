import React, {useState} from "react";
import MapGl, {Marker} from "react-map-gl"

import { FaMapMarkerAlt } from "react-icons/fa";
import { IconContext } from "react-icons";

function Map() {
  const [viwport, setViwport] = useState({
    latitude: 48.1691869,
    longitude: 11.6471292,
    width: "500px",
    height: "300px",
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });
  return (
    <div>
      <MapGl
        {...viwport}
        width={500}
        height={300}
        mapboxApiAccessToken={
          "pk.eyJ1IjoiYWRtaW5sb3JlbnoiLCJhIjoiY2tuY3dvdnI4MjYxbzJ4cDk3dGtxYjJleSJ9.E9a3H0vnVOqjczKRrf3LRQ"
        }
        mapStyle={"mapbox://styles/mapbox/streets-v11"}
        tilesetID=""
        onViewportChange={(viwport) => {
          setViwport(viwport);
        }}
      >
        <Marker
          latitude={48.1691869}
          longitude={11.6471292}
          offsetLeft={-15}
          offsetTop={-15}
        >
          <div>
            <IconContext.Provider value={{ color: "#ee6611", size: "30px" }}>
              <a
                target="_blank"
                href="https://goo.gl/maps/85PvP3S471hfra5F7"
                rel="noreferrer"
              >
                <FaMapMarkerAlt />
              </a>
            </IconContext.Provider>
          </div>
        </Marker>
      </MapGl>
    </div>
  );
}

export default Map;


