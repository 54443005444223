import React, { Component } from "react";
import { Link} from "react-router-dom";
import "../static/css/gallerie.css";

import { IoChevronBack } from "react-icons/io5";
import { IconContext } from "react-icons";

// BsBackspace
// BsFillBackspaceFill

// IoChevronBackOutline


class BackNav extends Component {
  
  
  render() {
    return (
      <div className="GallerieTop">
        <div className="BackLink">
          <IconContext.Provider
            value={{
              color: "#000",
              className: "global-class-name",
              size: "30px",
            }}
          >
            <Link to="/">
              <div className="tooltipCont">
                <IoChevronBack />
              </div>
            </Link>
          </IconContext.Provider>
        </div>
        <div className="PageHeadding">
          <p>{this.props.pageTitel}</p>
        </div>
      </div>
    );
    }
}

export default BackNav;