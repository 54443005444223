import React, { Component } from "react";

import "../static/css/gallerie.css";

import BackNav from "../components/BackNav.js";



import p1 from "../static/img/Histoirie/historie1.jpg";
import p2 from "../static/img/Histoirie/historie2.jpg";
import p3 from "../static/img/Histoirie/historie3.jpg";
import p4 from "../static/img/Histoirie/historie4.jpg";
import p5 from "../static/img/Histoirie/historie5.jpg";


class Historie extends Component {
  render() {
    return (
      <div className="Gallerie">
        <BackNav pageTitel={"Historie"} />
        <div className="imgContainer">
          <img src={p1} alt=" "/>
          <img src={p2} alt=" "/>
          <img src={p3} alt=" "/>
          <img src={p4} alt=" "/>
          <img src={p5} alt=" "/>
        </div>
      </div>
    );
  }
}

export default Historie;
