import React, { Component } from "react";
import "../static/css/philosophie.css";


import BackNav from "../components/BackNav.js";


import img1 from "../static/img/backgroundImg.jpg";

class Philosophie extends Component {
  render() {
    return (
      <div className="PhiloContainer">
        <BackNav pageTitel={"Philo-Sophie"} />

        <div className="textContainer">
          <p>
            <div className="firsimg">
              <div className="PhiloText">
                <g>Die Dicke Sophie</g>
                <br />
                Das Wirtshaus/Biergarten Dicke Sophie hat Ihren Namen bereits
                seit 1994. Vor dieser Zeit wurde das Wirtshaus unter dem Namen
                „Alter Wirt“ geführt. Unsere Philo Sophie ist eigentlich relativ
                leicht zu erklären. <br />
                <br />
                <g>
                  Wir setzen uns stets zum Ziel, unseren Gästen den Aufenthalt
                  bei uns so angenehm wie möglich zu gestalten.
                </g>{" "}
                <br />
                <br />
                Dies versuchen wir mit freundlichem, bodenständigen Service und
                täglich frisch und à la minute zubereitete Speisen und
                Gerichten. Wir legen hier schon beim Einkauf der Lebensmittel
                großen Wert auf Qualität und Frische. Viermal wöchentlich werden
                wir sowohl von unserem Stammmetzger, wie auch von unserem Obst-
                und Gemüsehändler beliefert.
                <br />
                <br />
                {/* Im Sommer 2012 haben wir ein Stück bayerische
                Biergartentradition bei uns wieder aufleben lassen. */}
              </div>
              <img src={img1} alt="img"></img>
            </div>
            {/* In unserem Biergarten haben wir zusätzlich zu unserem bedienten
            Servicegarten (ca. 280 Plätze),einen kleinen Selbstbedienungsbereich
            mit etwa 100 Plätzen geschaffen, in welchem unsere Gäste die
            Möglichkeit haben sich ihre Brotzeit selbst mitzubringen, um sich
            dann bei einer kühlen Maß Bier vom Fass aus unserer SB-Station,
            unter Kastanienbäumen den Biergartentag in bei uns so richtig
            schmecken zu lassen. Ihr Dicke Sophie Team */}
          </p>
        </div>
      </div>
    );
  }
}

export default Philosophie;
